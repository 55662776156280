import React from "react";
import { useLocation } from "react-router-dom";
import js from "./images/skills/javascript.png";
import ts from "./images/skills/typescript.png";
import html from "./images/skills/html.png";
import css from "./images/skills/css.png";
import react from "./images/skills/react.png";
import redux from "./images/skills/redux.png";
import node from "./images/skills/node.png";
import postgres from "./images/skills/postgres.png";
import git from "./images/skills/git.png";
import rest from "./images/skills/rest-api.png";
import sql from "./images/skills/sql.png";

const experiences = [
  {
    title: "YARDI SYSTEMS | Associate Technical Account Manager | Melville, NY",
    date: "June 2024 — Present",
    description:
      "Provide end-to-end technical support and system maintenance for complex accounting issues across Yardi’s real estate software suite, ensuring optimal performance and alignment with client business objectives. Oversee Yardi system configuration and maintenance, including entity setup, ownership structure updates, and data table management, enhancing data accuracy and streamlining financial reporting. Document and analyze software issues, lead troubleshooting efforts, and manage bug detection processes, improving system reliability through strategic issue tracking and resolution. Lead new client implementation projects, utilizing SQL for data extraction and querying, and ensuring seamless integration of Yardi solutions with existing client systems. Spearheaded Hyper Care program for post-implementation support, ensuring smooth Yardi integration. Conduct regular system audits and ensure data integrity across financial systems, identifying and implementing improvements in data workflows and reporting accuracy. Collaborate with cross-functional teams on testing and deploying system enhancements, service releases, and upgrades, aligning technology improvements with client financial goals and operational requirements. Provide training and knowledge transfer to clients and internal teams on Yardi functionalities, ensuring efficient use and adoption of platform features.",
  },
  {
    title: "PAK'S WATCHES AND DIALS | Web Developer | New York, NY",
    date: "Mar 2023 — June 2024",
    description:
      "Orchestrated the development of a dynamic React website, optimizing web vitals for a >15% increase in customer traffic. Created an internal web application to manage inventory and track lost and stolen watches. Pioneered the integration of an online presence, strategically targeting a younger audience for virtual exploration of luxury timepieces and custom dials. Leveraged EmailJS to craft efficient email templates, initiating a new sales process, streamlining appointment reservations, and verifications for special requests via the 'Contact Us' page. Utilized responsive design for a seamless and visually appealing experience across devices, contributing to heightened user engagement.",
  },
  {
    title: "LANDIS | Business Development | New York, NY",
    date: "Oct 2021 — Jan 2023",
    description:
      "Hired as the first Business Development Representative at an early-stage startup, I established the groundwork for cold outreach processes. Utilizing HubSpot tools, I automated new cadences, achieving a remarkable 75% response rate. Successfully pitching the sales playbook, I acquired new accounts, contributing to a 25% monthly increase in active users. Additionally, I collaborated cross-functionally with marketing, operations, and engineering teams to enhance the overall user experience.",
  },
  {
    title: "ADVANTAGE HONDA | Sales Consultant | Manhasset, NY",
    date: "Mar 2018 — Mar 2020",
    description:
      "Consistently achieving an average monthly sales volume of 17 cars, I generated approximately $51,000 in monthly revenue. Effectively managing a comprehensive pipeline of Korean contacts, I guided them through the entire sales cycle. Leveraging DealerSocket’s CRM system, I proactively identified and cultivated new leads while maintaining engagement with existing ones. Furthermore, I conducted thorough cost-benefit and needs analyses to align with client requirements and ensure successful sales outcomes.",
  },
];

const educations = [
  {
    title: "Fullstack Academy",
    date: "2023",
    description:
      "Immersive web development program with emphasis on pair programming and rapid development",
  },
  {
    title: "CUNY Baruch College | New York, NY",
    date: "2020 — 2022",
    description:
      "Bachelor of Business Administration in Entrepreneurship | Major GPA: 3.525",
  },
];

const skills = [
  { name: "JavaScript", image: js },
  { name: " SQL", image: sql },
  { name: "TypeScript", image: ts },
  { name: "HTML", image: html },
  { name: "CSS", image: css },
  { name: "PostgreSQL", image: postgres },
  { name: "REST API", image: rest },
  { name: "React", image: react },
  { name: "Redux", image: redux },
  { name: "Git", image: git },
  { name: "Node.js", image: node },
];

const Resume = () => {
  const location = useLocation();

  return (
    <article
      className={`resume ${location.pathname === "/resume" ? "active" : ""}`}
      data-page="resume"
    >
      <header>
        <h2 className="h2 article-title">Resume</h2>
      </header>

      <section className="timeline">
        <div className="title-wrapper">
          <div className="icon-box">
            <ion-icon name="book-outline"></ion-icon>
          </div>

          <h3 className="h3">Experience</h3>
        </div>

        <ol className="timeline-list">
          {experiences.map((experience, index) => (
            <li className="timeline-item" key={index}>
              <h4 className="h4 timeline-item-title">{experience.title}</h4>
              <span>{experience.date}</span>
              <p className="timeline-text">{experience.description}</p>
            </li>
          ))}
        </ol>
      </section>

      <section className="timeline">
        <div className="title-wrapper">
          <div className="icon-box">
            <ion-icon name="book-outline"></ion-icon>
          </div>

          <h3 className="h3">Education</h3>
        </div>

        <ol className="timeline-list">
          {educations.map((education, index) => (
            <li className="timeline-item" key={index}>
              <h4 className="h4 timeline-item-title">{education.title}</h4>
              <span>{education.date}</span>
              <p className="timeline-text">{education.description}</p>
            </li>
          ))}
        </ol>
      </section>

      <section className="skills">
        <h3 className="h3 skills-title">Skills</h3>
        <ul className="skills-list has-scrollbar">
          {skills.map((skill, index) => (
            <li className="skills-item" key={index}>
              <img src={skill.image} alt={skill.name} />
              <span style={{ color: "hsl(45, 54%, 58%)", textAlign: "center" }}>
                {skill.name}
              </span>
            </li>
          ))}
        </ul>
      </section>
    </article>
  );
};

export default Resume;
